import React, {Component} from "react";
import CustomLegend from './CustomLegend';
import './App.css';
import {
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LineChart,CartesianGrid, Line
} from "recharts";
import { io } from "socket.io-client";
// const ENDPOINT = "localhost:8080";
const ENDPOINT = "https://vps.smythe.fr";

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      socket: io(ENDPOINT),
      vh_in_px: document.documentElement.clientHeight,
      vw_in_px: document.documentElement.clientWidth,
      response: [],
    }
  }

  componentDidMount(){
    this.state.socket.on('esp32_news', (data) => {
      this.setState(function(prev){
        let foo = prev.response;
        if(foo.length >= 59){
          foo = foo.slice(1, foo.length);
        }else if(foo.length<59){
          let empty = new Array();
          empty.length = 59-foo.length;
          empty.fill(foo[0] ? foo[0].temperature : 0)
          foo = empty.concat(foo);
        }
        foo.push(data);
        return({response:foo});
      })
    })
  }

  render(){
    const {response, vh_in_px, vw_in_px} = this.state;
    console.log(response);
    return (
      <>
        <LineChart width={vh_in_px*0.8} height={vh_in_px*0.33} data={response}
                   margin={{top: 5, right: 30, left: 20, bottom: 5}}>
          <XAxis dataKey="time" />
          <YAxis domain={[18, 26]}/>
          <CartesianGrid strokeDasharray="3 3"/>
          <Tooltip/>
          <Legend content={<CustomLegend current={response[response.length - 1] && (response[response.length - 1].temperature) + " °C"}/>}/>
          <Line type="natural" isAnimationActive={false} dataKey="temperature" stroke="#DB7F53" activeDot={{r: 8}}/>
        </LineChart>
        <LineChart width={vh_in_px*0.8} height={vh_in_px*0.33} data={response}
                   margin={{top: 5, right: 30, left: 20, bottom: 5}}>
          <XAxis dataKey="time" />
          <YAxis domain={[10, 1000]}/>
          <CartesianGrid strokeDasharray="3 3"/>
          <Tooltip/>
          <Legend content={<CustomLegend current={response[response.length - 1] && (response[response.length - 1].light) + " Lux"}/>}/>
          <Line type="natural" isAnimationActive={false} dataKey="light" stroke="#E1D565" activeDot={{r: 8}}/>
        </LineChart>
        <LineChart width={vh_in_px*0.8} height={vh_in_px*0.33} data={response}
                   margin={{top: 5, right: 30, left: 20, bottom: 5}}>
          <XAxis dataKey="time" />
          <YAxis domain={[0, 100]}/>
          <CartesianGrid strokeDasharray="3 3"/>
          <Tooltip/>
          <Legend content={<CustomLegend current={response[response.length - 1] && (response[response.length - 1].humidity) + " %"}/>}/>
          <Line type="natural" isAnimationActive={false} dataKey="humidity" stroke="#00A9FF" activeDot={{r: 8}}/>
        </LineChart>
      </>
    );
  }
}
