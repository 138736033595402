import React from "react";

export default function CustomLegend(props) {
  const { payload, current } = props;
  console.log(payload[0].color);
  return (
    <ul style={{listStyleType: 'none', display: 'flex', justifyContent:'center'}}>
      {
        payload.map((entry, index) => (
          <li key={`item-${index}`} style={{display: "flex", alignItems: "center"}}>
            <svg className={"recharts-surface"} width={20} height={20} viewBox={"0 0 32 32"} version={"1.1"}
                 style={{display: "inline-block", verticalAlign: "middle", marginRight: "4px"}}>
              <path stroke-width="3" fill="none" stroke={payload[0].color} d="M0,16h10.666666666666666
            A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
            H32M21.333333333333332,16
            A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16" className="recharts-legend-icon"></path>
            </svg>
            <span style={{color:payload[0].color, fontSize: '1.2em', marginLeft:"20px"}}>{entry.value} ({current})</span>
          </li>
        ))
      }
    </ul>
  );
}